import React from 'react';
import ExerciseSummaryWidget from './ExerciseSummaryWidget.js';
import Summary from './Summary.js';
import './../css/Home.css';

class Home extends React.Component {
   render() {
      return (
         <div className='home-container'>
            <Summary
               workoutDefinitionService={this.props.workoutDefinitionService}
               workoutInstanceService={this.props.workoutInstanceService}
               exerciseInstanceService={this.props.exerciseInstanceService}
            ></Summary>
            <ExerciseSummaryWidget
               workoutDefinitionService={this.props.workoutDefinitionService}
               exerciseDefinitionService={this.props.exerciseDefinitionService}
               exerciseInstanceService={this.props.exerciseInstanceService}
               exerciseDefinitionName="Barbell Bench"
            >
            </ExerciseSummaryWidget>
            <ExerciseSummaryWidget
               workoutDefinitionService={this.props.workoutDefinitionService}
               exerciseDefinitionService={this.props.exerciseDefinitionService}
               exerciseInstanceService={this.props.exerciseInstanceService}
               exerciseDefinitionName="Dumbbell Bench"
            >
            </ExerciseSummaryWidget>
            <ExerciseSummaryWidget
               workoutDefinitionService={this.props.workoutDefinitionService}
               exerciseDefinitionService={this.props.exerciseDefinitionService}
               exerciseInstanceService={this.props.exerciseInstanceService}
               exerciseDefinitionName="Squat"
            >
            </ExerciseSummaryWidget>
            <ExerciseSummaryWidget
               workoutDefinitionService={this.props.workoutDefinitionService}
               exerciseDefinitionService={this.props.exerciseDefinitionService}
               exerciseInstanceService={this.props.exerciseInstanceService}
               exerciseDefinitionName="Dumbbell Row"
            >
            </ExerciseSummaryWidget>
            <ExerciseSummaryWidget
               workoutDefinitionService={this.props.workoutDefinitionService}
               exerciseDefinitionService={this.props.exerciseDefinitionService}
               exerciseInstanceService={this.props.exerciseInstanceService}
               exerciseDefinitionName="Dumbbell Curls"
            >
            </ExerciseSummaryWidget>
            <ExerciseSummaryWidget
               workoutDefinitionService={this.props.workoutDefinitionService}
               exerciseDefinitionService={this.props.exerciseDefinitionService}
               exerciseInstanceService={this.props.exerciseInstanceService}
               exerciseDefinitionName="Hammer Curls"
            >
            </ExerciseSummaryWidget>
            <ExerciseSummaryWidget
               workoutDefinitionService={this.props.workoutDefinitionService}
               exerciseDefinitionService={this.props.exerciseDefinitionService}
               exerciseInstanceService={this.props.exerciseInstanceService}
               exerciseDefinitionName="Barbell Incline Bench"
            >
            </ExerciseSummaryWidget>
            <ExerciseSummaryWidget
               workoutDefinitionService={this.props.workoutDefinitionService}
               exerciseDefinitionService={this.props.exerciseDefinitionService}
               exerciseInstanceService={this.props.exerciseInstanceService}
               exerciseDefinitionName="Dumbbell Incline Fly"
            >
            </ExerciseSummaryWidget>
            <ExerciseSummaryWidget
               workoutDefinitionService={this.props.workoutDefinitionService}
               exerciseDefinitionService={this.props.exerciseDefinitionService}
               exerciseInstanceService={this.props.exerciseInstanceService}
               exerciseDefinitionName="Kettleball Oblique"
            >
            </ExerciseSummaryWidget>
            <ExerciseSummaryWidget
               workoutDefinitionService={this.props.workoutDefinitionService}
               exerciseDefinitionService={this.props.exerciseDefinitionService}
               exerciseInstanceService={this.props.exerciseInstanceService}
               exerciseDefinitionName="Barbell Row"
            >
            </ExerciseSummaryWidget>
            <ExerciseSummaryWidget
               workoutDefinitionService={this.props.workoutDefinitionService}
               exerciseDefinitionService={this.props.exerciseDefinitionService}
               exerciseInstanceService={this.props.exerciseInstanceService}
               exerciseDefinitionName="Shoulder Raises"
            >
            </ExerciseSummaryWidget>
            <ExerciseSummaryWidget
               workoutDefinitionService={this.props.workoutDefinitionService}
               exerciseDefinitionService={this.props.exerciseDefinitionService}
               exerciseInstanceService={this.props.exerciseInstanceService}
               exerciseDefinitionName="Military Press"
            >
            </ExerciseSummaryWidget>
            <ExerciseSummaryWidget
               workoutDefinitionService={this.props.workoutDefinitionService}
               exerciseDefinitionService={this.props.exerciseDefinitionService}
               exerciseInstanceService={this.props.exerciseInstanceService}
               exerciseDefinitionName="Barbell Shrugs"
            >
            </ExerciseSummaryWidget>
            <ExerciseSummaryWidget
               workoutDefinitionService={this.props.workoutDefinitionService}
               exerciseDefinitionService={this.props.exerciseDefinitionService}
               exerciseInstanceService={this.props.exerciseInstanceService}
               exerciseDefinitionName="Barbell Reverse Curl"
            >
            </ExerciseSummaryWidget>
         </div>
      );
   }
}

export default Home;