import React from 'react';
import './../css/Toolbar.css';
import { Modal } from '@material-ui/core';
import ToolbarLink from './ToolbarLink.js';
import WorkoutInstanceBuilder from './WorkoutInstanceBuilder.js';
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import logo from "../img/logo-text.png";
import AddIcon from '@material-ui/icons/Add';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

class Toolbar extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            open: false
        }

        this.openModel = this.openModel.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.signOut = this.signOut.bind(this);
    }

    openModel(event) {
        this.setState({
            open: true
        });
    }

    closeModal(){
        this.setState({
            open: false
        });
    }

    signOut(){
        Auth.signOut().then(function(event){
            window.location.reload();
        });
    }

    render() {
        return (
            <div className="toolbar">
                <div className="tool-bar-links-container">
                    <Link to="/"><img className="toolbar-logo" alt="website-logo" src={logo}></img></Link>
                    <ToolbarLink route="/workouts" label="Workouts"></ToolbarLink>
                    <ToolbarLink route="/exercises" label="Exercises"></ToolbarLink>
                    {/* <ToolbarLink route="/muscle-groups" label="Muscle Groups"></ToolbarLink> */}
                </div>
                <div className="toolbar-button-container">
                    <button className="primary-button" onClick={this.openModel}><AddIcon/> Log Workout</button>
                    <button className="secondary-button sign-out-button" onClick={this.signOut}><ExitToAppIcon/> Sign Out</button>
                </div>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.open}
                    onBackdropClick={this.closeModal}
                >
                    <WorkoutInstanceBuilder
                        workoutInstanceService={this.props.workoutInstanceService}
                        exerciseDefinitionService={this.props.exerciseDefinitionService}
                        workoutDefinitionService={this.props.workoutDefinitionService}
                        exerciseInstanceService={this.props.exerciseInstanceService}
                    ></WorkoutInstanceBuilder>
                </Modal>
            </div>
        );
    }
}

export default Toolbar;