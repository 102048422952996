import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import LineChart from 'react-linechart';
import '../../node_modules/react-linechart/dist/styles.css';
import './../css/Exercises.css';

class Exercises extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            exerciseDefinitionRows: [],
            dialogOpen: false,
            isLoadingTable: true,
            chartData: [],
            totalLogged: "",
            personalBest: "",
            personalBestDate: "",
            exerciseDefinitionName: "",
            hasExerciseDefinitionSelected: false
        }

        this.showDefinitionMetrics = this.showDefinitionMetrics.bind(this);
        this.filterExerciseDefinitionTable = this.filterExerciseDefinitionTable.bind(this);
    }

    componentDidMount() {
        let rows = [];
        this.props.exerciseDefinitionService.getAllExerciseDefinitions().then(function(response){
            this.allExerciseDefinitions = response.data.Items;
            let exerciseDefSet = new Set();
            for (let i in this.allExerciseDefinitions) {
                let definitionName = this.allExerciseDefinitions[i].definitionName.S;
                if (!exerciseDefSet.has(definitionName)){
                    rows.push(
                        <div key={i} className="search-result-row" onClick={this.showDefinitionMetrics.bind(this, definitionName)}>
                            {definitionName}
                        </div>
                    );
                    exerciseDefSet.add(definitionName);
                }
            }
            this.setState({
                exerciseDefinitionRows: rows,
                isLoadingTable: false
            });
        }.bind(this));
    }

    filterExerciseDefinitionTable(event){
        let value = document.getElementById("exerciseDefinitionSearchInput").value;
        let arr = this.allExerciseDefinitions;
        arr = arr.filter(function(exerciseDef){
            let exerciseDefName = exerciseDef.definitionName.S;
            return exerciseDefName.indexOf(value) > -1;
        });
        
        let exerciseDefSet = new Set();
        let rows = [];
        for (let i in arr) {
            let definitionName = arr[i].definitionName.S;
            if (!exerciseDefSet.has(definitionName)){
                rows.push(
                    <div key={i} className="search-result-row" onClick={this.showDefinitionMetrics.bind(this, definitionName)}>
                        {definitionName}
                    </div>
                );
                exerciseDefSet.add(definitionName);
            }
        }
        this.setState({
            exerciseDefinitionRows: rows,
            isLoadingTable: false
        });
    }
    
    showDefinitionMetrics(exerciseDefinitionName, event){
        let exerciseDefinitionIdsForName = [];
        for (let j in this.allExerciseDefinitions){
            let def = this.allExerciseDefinitions[j];
            if (def.definitionName.S === exerciseDefinitionName){
                exerciseDefinitionIdsForName.push(def.id.S);
            }
        }
        this.props.exerciseInstanceService.getExerciseInstancesByDefinitonIds(exerciseDefinitionIdsForName).then(function(event){
            // should probably return the items already sorted
            let exerciseInstances = event.data.Items.filter(function(instance){
                return instance.date;
            }).sort(function(a, b){
                let aTimeStamp = parseFloat(a.date.N);
                let bTimeStamp = parseFloat(b.date.N);
                return aTimeStamp - bTimeStamp;
            });
            let sortedData = {};
            let personalBest = -1;
            let personalBestDate = "";
            for (let i in exerciseInstances){
                let instance = exerciseInstances[i];
                let weight = instance.weight.N;
                let timestamp = instance.date.N;
                let date = new Date(parseFloat(timestamp));
                let exerciseDefinitionId = instance.exerciseDefinitionId.S;
                if (parseInt(weight) > personalBest){
                    personalBest = parseInt(weight);
                    personalBestDate = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
                }
                if (!sortedData[exerciseDefinitionId]){
                    let arrCopy = this.allExerciseDefinitions;
                    arrCopy = arrCopy.filter(function(def){
                        return def.id.S === exerciseDefinitionId;
                    });
                    sortedData[exerciseDefinitionId] = {
                        points: [],
                        lineName: "Sets: " + arrCopy[0].sets.N + " Reps: " + arrCopy[0].reps.N
                    }
                } else {
                    let arr = sortedData[exerciseDefinitionId].points;
                    arr.push({
                        x: date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
                        y: weight
                    });
                    sortedData[exerciseDefinitionId].points = arr;
                }
            }
            let data = [];
            let colorArr = ["#152028", "#47555E", "#828B92", "white"];
            let counter = 0;
            for (let key in sortedData){
                if (sortedData[key].points.length > 0){
                    data.push({
                        color: colorArr[counter++],
                        points: sortedData[key].points,
                        name: sortedData[key].lineName
                    });
                }
            }
            this.setState({
                chartData: data,
                exerciseDefinitionName: exerciseDefinitionName,
                personalBest: personalBest,
                totalLogged: exerciseInstances.length,
                personalBestDate: personalBestDate.toString(),
                hasExerciseDefinitionSelected: true
            });
        }.bind(this));
    }

    render() {
        return (
            <div>
                <div className="exercise-definition-search-container">
                    <div className="exercise-definition-search-header">
                        <div className="exercise-search-header-label">Exercises</div>
                        <div>
                            <input className="exercise-definition-search-input" type="text" placeholder="Search" id="exerciseDefinitionSearchInput" onInput={this.filterExerciseDefinitionTable}></input>
                        </div>
                    </div>
                    <div hidden={!this.state.isLoadingTable} className="loading-spinner-container exercise-definition-table-loading-spinner-container">
                        <CircularProgress></CircularProgress>
                    </div>
                    <div hidden={this.state.isLoadingTable} className="exercise-definition-search-results-container">
                        {this.state.exerciseDefinitionRows}
                    </div>
                </div>
                <div 
                    style={(this.state.exerciseDefinitionName.length === 0) ? {} : { display: 'none' }}
                    className="exercise-definition-metrics-container-placeholder">
                    <div>Select an exercise to see your progress</div>
                </div>
                <div className="exercise-definition-metrics-container"
                    style={(this.state.chartData.length > 0) ? {} : { display: 'none' }}
                >
                    {/* <div className="metric-container-header">{this.state.exerciseDefinitionName}</div> */}
                    {/* <span>Last Performed {this.state.lastPerformedDate}</span> */}
                    {/* <div className="exercise-personal-best-container card">
                        <div className="exercise-metric-card-title">Personal Best</div>
                        <div className="personal-best-metric">{this.state.personalBest} <span className="lbs-label">lbs</span></div>
                        <div>{this.state.personalBestDate}</div>
                    </div>
                    <div className="exercise-count-container card">
                        <div className="exercise-metric-card-title">Totals</div>
                        <div>Logged {this.state.totalLogged} times</div>
                    </div> */}
                    <div className="timeline-container card">
                        <div className="metric-container-header">{this.state.exerciseDefinitionName}</div>
                        <LineChart 
                            data={this.state.chartData}
                            xLabel="Date"
                            yLabel="Weight (lbs)"
                            yMin="0"
                            yMax="300"
                            isDate="true"
                            height="500"
                            onPointHover={(obj) => `${obj.y} lbs`}
                            showLegends="true"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Exercises;