import React from 'react';
import './../css/ToolbarLink.css';
import { Link } from "react-router-dom";

class Toolbar extends React.Component {
    render() {
        return (
            <div className="toolbar-link">
                <Link className="link" to={this.props.route}>{this.props.label}</Link>
            </div>
        );
    }
}

export default Toolbar;