class WorkoutDefinitionService {
  constructor(amplifyRequestService, apiName) {
    this.amplifyRequestService = amplifyRequestService;
    this.apiName = apiName;
    this.workoutDefinitionCache = {};
  }

  getAllWorkoutDefinitions() {
    if (this.allWorkoutDefinitions){
      return this.allWorkoutDefinitions;
    }
    this.allWorkoutDefinitions = this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/workout-definitions', "GET");
    return this.allWorkoutDefinitions;
  }

  getWorkoutDefinitionsForExerciseDefinitionId(exerciseDefinitionId){
    if (this.workoutDefinitionCache[exerciseDefinitionId]){
      return this.workoutDefinitionCache[exerciseDefinitionId];
    }
    let myInit = {
      queryStringParameters: {
        exerciseDefinitionIds: [exerciseDefinitionId]
      }
    }
    this.workoutDefinitionCache[exerciseDefinitionId] = this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/workout-definitions', "GET", myInit);
    return this.workoutDefinitionCache[exerciseDefinitionId];
  }

  getWorkoutDefinitionsForExerciseDefinitionIds(exerciseDefinitionIds){
    if (this.workoutDefinitionCache[exerciseDefinitionIds.toString()]){
      return this.workoutDefinitionCache[exerciseDefinitionIds.toString()];
    }
    let myInit = {
      queryStringParameters: {
        exerciseDefinitionIds: exerciseDefinitionIds
      }
    }
    this.workoutDefinitionCache[exerciseDefinitionIds.toString()] = this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/workout-definitions', "GET", myInit);
    return this.workoutDefinitionCache[exerciseDefinitionIds.toString()];
  }

  addWorkoutDefinition(definitionName, exerciseDefinitionIds, exerciseDefinitions){
    let exerciseDefinition = {
        body: {
          "definitionName":definitionName,
          "exerciseDefinitionIds": exerciseDefinitionIds,
          "exerciseDefinitions": exerciseDefinitions
        }
    }
    return this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/workout-definitions', "PUT", exerciseDefinition);
  }
}

export default WorkoutDefinitionService;