class MuscleGroupService {
    constructor() {
      this.muscleGroups = require('./../sample-db/muscleGroups.js');
    }

    getAllMuscleGroups() {
        return this.muscleGroups.getData();
    }

    getMuscleGroupBy(id) {
      let allDefinitions = this.muscleGroups.getData();
      for (let i in allDefinitions) {
        let def = allDefinitions[i];
        if (def.id === id) {
          return def;
        }
      }
    }

    getMuscleGroupByExerciseDefinitionName(exerciseDefinitionName) {
      let allDefinitions = this.muscleGroups.getData();
      for (let i in allDefinitions) {
        let def = allDefinitions[i];
        if (def.exerciseDefinitionName === exerciseDefinitionName) {
          return def;
        }
      }
    }
}

export default MuscleGroupService;