import React from 'react';
import './../css/WorkoutDefinitionCard.css';
import ExerciseDefinitionRowRenderer from './ExerciseDefinitionRowRenderer.js';
import CircularProgress from '@material-ui/core/CircularProgress';


class WorkoutDefinitionCard extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            "exerciseDefRows": [],
            "avgDuration": "N/A",
            "isLoadingData": true
        }
    }

    componentDidMount() {
        let dynamoIds = this.props.workoutDefinition.exerciseDefinitionIds.L;
        let exerciseDefIds = [];
        for (let i in dynamoIds){
            exerciseDefIds.push(dynamoIds[i].S);
        }
        this.props.exerciseDefinitionService.getExerciseDefinitionByIds(exerciseDefIds).then(function(response){
            let exerciseDefRows = [];
            this.exerciseDefinitions = response.data.Items;
            for (let i in this.props.workoutDefinition.exerciseDefinitionIds.L) {
                let defId = this.props.workoutDefinition.exerciseDefinitionIds.L[i].S;
                let definition = undefined;
                for (let j in this.exerciseDefinitions){
                    if (defId === this.exerciseDefinitions[j].id.S){
                        definition = this.exerciseDefinitions[j];
                        break;
                    }
                }
                exerciseDefRows.push(
                    <ExerciseDefinitionRowRenderer
                        key={i}
                        definition={definition}
                    >
                    </ExerciseDefinitionRowRenderer>
                )
            }
            this.setState({
                "exerciseDefRows": exerciseDefRows,
                isLoadingData: false
            });
        }.bind(this))
        
        this.props.workoutInstanceService.getWorkoutInstancesByDefinitionId(this.props.workoutDefinition.id.S).then(function(response){
            let totalDuration = 0;
            let allowedInstances = 0;
            this.workoutInstances = response.data.Items;
            this.workoutInstances.sort(function (a, b) {
                if (a.date.N > b.date.N) {
                    return -1;
                }
                if (a.date.N < b.date.N) {
                    return 1;
                }
                return 0;
            });
            for (let j in this.workoutInstances){
                let instance = this.workoutInstances[j];
                if (parseInt(instance.duration.N) !== -1){
                    totalDuration += parseInt(instance.duration.N);
                    allowedInstances++;
                }
            }

            let avgDuration = -1;
            if (allowedInstances > 0){
                avgDuration = totalDuration/allowedInstances;
                this.setState({
                    "avgDuration": avgDuration + " min"
                });
            }
        }.bind(this));
    }

    render() {
        return (
            <div className='workout-definition-card-container'>
                <div className="workout-definition-card-definition-name">{this.props.workoutDefinition.definitionName.S}</div>
                <div className="workout-avg-duration-container">Avg Duration: {this.state.avgDuration}</div>
                <div hidden={!this.state.isLoadingData} className="loading-spinner-container exercise-definition-table-loading-spinner-container">
                        <CircularProgress></CircularProgress>
                </div>
                {this.state.exerciseDefRows}
            </div>
        );
    }
}

export default WorkoutDefinitionCard;