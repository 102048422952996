import React from 'react';
import WorkoutDefinitionTable from './WorkoutDefinitionTable.js';
import Home from './Home.js';
import MuscleGroups from './MuscleGroups.js';
import ArchitecturePage from './ArchitecturePage.js';
import Exercises from './Exercises.js'
import {
  Switch,
  Route
} from "react-router-dom";

class AppRouter extends React.Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route path="/workouts">
                        <WorkoutDefinitionTable
                        workoutDefinitionService={this.props.workoutDefinitionService}
                        workoutInstanceService={this.props.workoutInstanceService}
                        exerciseDefinitionService={this.props.exerciseDefinitionService}
                        muscleGroupService={this.props.muscleGroupService}
                        exerciseInstanceService={this.props.exerciseInstanceService}
                        >
                        </WorkoutDefinitionTable>
                    </Route>
                    <Route path="/exercises">
                        <Exercises 
                        exerciseInstanceService={this.props.exerciseInstanceService}
                        exerciseDefinitionService={this.props.exerciseDefinitionService} 
                        >
                        </Exercises>
                    </Route>
                    <Route path="/muscle-groups">
                        <MuscleGroups 
                        muscleGroupService={this.props.muscleGroupService}
                        >
                        </MuscleGroups>
                    </Route>
                    <Route path="/architecture">
                        <ArchitecturePage
                            productStatisticsService={this.props.productStatisticsService}
                        ></ArchitecturePage>
                    </Route>
                    <Route path="/">
                        <Home 
                         workoutDefinitionService={this.props.workoutDefinitionService}
                         workoutInstanceService={this.props.workoutInstanceService}
                         exerciseDefinitionService={this.props.exerciseDefinitionService} 
                         exerciseInstanceService={this.props.exerciseInstanceService}
                         ></Home>
                    </Route>
                </Switch>
            </div>
        );
    }
}

export default AppRouter;