class WorkoutInstanceService {
  constructor(amplifyRequestService, apiName) {
    this.amplifyRequestService = amplifyRequestService;
    this.apiName = apiName;
    this.workoutInstanceCache = {};
  }

  getAllWorkoutInstances() {
    if (this.allWorkoutInstances) {
      return this.allWorkoutInstances;
    }
    this.allWorkoutInstances = this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/workout-instances', "GET");
    return this.allWorkoutInstances;
  }

  getWorkoutInstancesByDefinitionId(definitionId){
    if (this.workoutInstanceCache[definitionId]){
      return this.workoutInstanceCache[definitionId];
    }
    let myInit = {
      queryStringParameters: {
        workoutDefinitionIds: [definitionId]
      }
    }
    this.workoutInstanceCache[definitionId] = this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/workout-instances', "GET", myInit);
    return this.workoutInstanceCache[definitionId];
  }

  addWorkoutInstance(date, workoutDefinitionId, exerciseInstanceIds, duration){
    let workoutInstance = {
        body: {
          "date":date,
          "workoutDefinitionId":workoutDefinitionId,
          "exerciseInstanceIds":exerciseInstanceIds,
          "duration":duration
        }
    }
    return this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/workout-instances', "PUT", workoutInstance);
  }
}

export default WorkoutInstanceService;