import React from 'react';
import './../css/ArchitecturePage.css';
import architectureDiagram from "../img/architecture-diagram-v0.1.png";
import me from "../img/me.png";
import linkedin from "../img/linked-in.svg";
import github from "../img/github.png";

class ArchitecturePage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            estimatedNumberOfUsers: "",
            estimatedMonthlyCost: ""
        }
    }

    componentDidMount(){
        this.props.productStatisticsService.getProductStatistics().then(function(event){
            this.setState({
                estimatedNumberOfUsers: event.data.estimatedUsers,
                estimatedMonthlyCost: "$" + parseFloat(event.data.estimatedMonthlyCost).toFixed(2)
            });
        }.bind(this));
    }

    render(){
        return (
            <div className="architecture-page-container">
                <div className="architecture-text-container">
                    <div className="me-container">
                        <img className="me" alt="me" src={me}></img>
                    </div>
                    <div className="me-info">
                        <span className="my-name-text">Seth Theeke</span><br/>
                        <span>seththeeke@gmail.com</span><br/>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/sptheeke">
                            <img className="icon" alt="linkedIn" src={linkedin}></img>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.github.com/seththeeke">
                            <img className="icon" alt="github" src={github}></img>
                        </a>
                    </div>
                    <h2 className="architecture-header-text">
                        Architecture
                    </h2>
                    <div>
                        <span className="architecture-stat-label">Estimated User Count: </span>
                        <span>{this.state.estimatedNumberOfUsers}</span><br/>
                        <span className="architecture-stat-label">Estimated Monthly Cost: </span>
                        <span>{this.state.estimatedMonthlyCost}</span><br/>
                    </div>
                    <div className="architecture-text">
                        myworkoutjournals.com is built using a cloud native architecture in AWS and utilizes serverless technology as much as possible. When a user navigates to the webpage, they do so through a Cloudfront distribution to static content in an S3 bucket where the Amplify framework controls the web applications authentication workflow with Cognito. The backend is made up of a series of Api Gateway deployments pointed towards synchronous Lambda functions that are performing CRUD on various DynamoDB tables.
                    </div>
                </div>
                <img className="architecture-diagram" alt="archDiagram" src={architectureDiagram}></img>
            </div>
        );
    }
}

export default ArchitecturePage;