import React from 'react';
import './../css/ExerciseDefinitionRowRenderer.css';

/**Renderer for exercise definition info on workout definition card */
class ExerciseDefinitionRowRenderer extends React.Component {
    render() {
        return (
            <div className="exercise-definition-row-container">
                <div className="exercise-definition-name">{this.props.definition.definitionName.S}</div>
                <div className="exercise-definition-desc">Sets: {this.props.definition.sets.N}</div>
                <div className="exercise-definition-desc">Reps: {this.props.definition.reps.N}</div>
            </div>
        );
    }
}

export default ExerciseDefinitionRowRenderer;