import React from 'react';
import WorkoutDefinitionCard from './WorkoutDefinitionCard.js';
import ExerciseDefinitionRowRenderer from './ExerciseDefinitionRowRenderer.js';
import './../css/WorkoutInstanceBuilder.css';
import SaveIcon from '@material-ui/icons/Save';

class WorkoutInstanceBuilder extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            formHeader: [],
            workoutSearchResults: [],
            workoutDefinitionForm: [],
            workoutDefinitionSelected: {},
            hoveredWorkoutDefinition: [],
            "processingText": ""
        }

        this.handleSearchInput = this.handleSearchInput.bind(this);
        this.createWorkoutInstanceForm = this.createWorkoutInstanceForm.bind(this);
        this.renderWorkoutDefinition = this.renderWorkoutDefinition.bind(this);
        this.saveWorkoutInstance = this.saveWorkoutInstance.bind(this);
    }

    handleSearchInput(){
        this.props.workoutDefinitionService.getAllWorkoutDefinitions().then(function(response){
            let allWorkoutDefs = response.data.Items;
            let partialName = document.getElementById("workoutSearchInput").value;
            let workoutDefResults = [];
            if (partialName && partialName.length > 0){
                workoutDefResults = allWorkoutDefs.filter(function(workoutDef){
                    return workoutDef.definitionName.S.includes(partialName);
                });
            }

            let cards = [];
            for (let i in workoutDefResults){
                let definition = workoutDefResults[i];
                cards.push(
                    <div 
                        className="workout-definition-search-result-container" 
                        key={definition.id.S} 
                        onClick={this.createWorkoutInstanceForm.bind(this, definition)}
                        onMouseOver={this.renderWorkoutDefinition.bind(this, definition)}
                    >
                        {definition.definitionName.S}
                    </div>
                )
            }

            this.setState({
                workoutSearchResults: cards
            });
        }.bind(this));
    }

    createWorkoutInstanceForm(definition, event){
        let header = [];
        let exerciseDefIdsDynamo = definition.exerciseDefinitionIds.L;
        let exerciseDefIds = [];
        for (let i in exerciseDefIdsDynamo){
            exerciseDefIds.push(exerciseDefIdsDynamo[i].S);
        }
        header.push(
            <div key="some-unique-key">
                <div className="workout-definition-card-definition-name">
                    {definition.definitionName.S}
                </div>
                <div className="header-input-container">
                    <div className="header-input-label">Date</div>
                    <input className="workout-instance-builder-header-input" type="date" id="workoutInstanceDateInput"></input>
                </div>
                <div className="header-input-container">
                    <div className="header-input-label">Duration</div>
                    <input className="workout-instance-builder-header-input" type="text" id="workoutInstanceDurationInput"></input>
                </div>
            </div>
        );
        this.props.exerciseDefinitionService.getExerciseDefinitionByIds(exerciseDefIds).then(function(response){
            let rows = [];
            let definitions = response.data.Items;
            for (let i in definitions){
                let exerciseDefinition = definitions[i];
                let inputIdName = exerciseDefinition.id.S.toString();
                rows.push(
                    <div key={exerciseDefinition.id.S}>
                        <div className="model-row">
                            <ExerciseDefinitionRowRenderer
                                definition={exerciseDefinition}
                            >
                            </ExerciseDefinitionRowRenderer> 
                        </div>
                        <div className="weight-input-container">
                            <input className="workout-instance-builder-input" type="text" id={inputIdName}></input>
                            <span className="weight-input-units">lbs</span>
                        </div>
                    </div>
                );
            }
            this.setState({
                workoutDefinitionForm: rows,
            });
        }.bind(this))
        this.setState({
            formHeader: header,
            hoveredWorkoutDefinition: [],
            workoutDefinitionSelected: definition
        });
    }

    renderWorkoutDefinition(definition, event){
        let workoutDefs = [];
        workoutDefs.push(
            <WorkoutDefinitionCard
                key={definition.id}
                workoutDefinition={definition} 
                workoutInstanceService={this.props.workoutInstanceService}
                exerciseDefinitionService={this.props.exerciseDefinitionService}
            >
            </WorkoutDefinitionCard>
        )
        this.setState({
            hoveredWorkoutDefinition: workoutDefs
        });
    }

    async saveWorkoutInstance(){
        let workoutDef = this.state.workoutDefinitionSelected;
        let exerciseDefinitionIds = workoutDef.exerciseDefinitionIds.L;
        let exerciseInstanceIds = [];
        this.setState({
            processingText: "Saving exercises..."
        });
        let date = document.getElementById("workoutInstanceDateInput").valueAsDate.getTime();
        for (let i in exerciseDefinitionIds){
            let inputId = exerciseDefinitionIds[i].S;
            let input = document.getElementById(inputId);
            let weight = input.value;
            
            let response = await this.props.exerciseInstanceService.addExerciseInstance(date, exerciseDefinitionIds[i].S, parseInt(weight));
            exerciseInstanceIds.push(response.data.id.S);
        }
        let workoutInstance = {
            "workoutDefinitionId":workoutDef.id.S,
            "date": date,
            "exerciseInstanceIds":exerciseInstanceIds,
            "duration":parseInt(document.getElementById("workoutInstanceDurationInput").value)
        }
        this.setState({
            processingText: "Saving workout..."
        });
        this.props.workoutInstanceService.addWorkoutInstance(workoutInstance.date, workoutInstance.workoutDefinitionId, workoutInstance.exerciseInstanceIds, workoutInstance.duration).then(function(response){
            this.setState({
                processingText: "Saved :)"
            });
        }.bind(this));
    }

    render() {
        return (
            <div className="workout-instance-builder-container">
                <div className="workout-instance-builder-header">Log Workout</div>
                <div className="search-workouts-input-container">
                    <input id="workoutSearchInput" onInput={this.handleSearchInput} className="search-workouts-input" type="text" placeholder="Search"></input>
                    <div className="search-results-dropdown-container">
                        {this.state.workoutSearchResults}
                    </div>
                    <div className="hovered-workout-definition-container">
                        {this.state.hoveredWorkoutDefinition}
                    </div>
                </div>
                <div className="workout-instance-form-container">
                    <div className="no-workout-definition-selected-container" hidden={this.state.workoutDefinitionForm.length > 0}>
                        <div className="no-workout-selected-text">Select a workout definition to add an instance</div>
                    </div>
                    <div hidden={!this.state.workoutDefinitionForm.length > 0}>
                        {this.state.formHeader}
                        {this.state.workoutDefinitionForm}
                        <div className="save-button-container">
                            <button 
                                hidden={!this.state.workoutDefinitionForm.length > 0}
                                className="secondary-button" 
                                onClick={this.saveWorkoutInstance}
                            >
                                <SaveIcon/> Save
                            </button>
                            <span>{this.state.processingText}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WorkoutInstanceBuilder;