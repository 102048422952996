import React from 'react';
import './../css/MuscleGroups.css';

// Currently inherits styles from Exercise Definition Table css, table should be broken into component
class MuscleGroups extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            muscleGroupRows: []
        }
    }

    componentDidMount() {
        let rows = [];
        let allMuscleGroups = this.props.muscleGroupService.getAllMuscleGroups();
        for (let i in allMuscleGroups) {
            rows.push(
                <tr key={i} className="table-row">
                    <td className="id-col table-row">{allMuscleGroups[i].id}</td>
                    <td className="exercise-col table-row">{allMuscleGroups[i].exerciseDefinitionName}</td>
                    <td className="sets-col table-row">{allMuscleGroups[i].primaryMuscles}</td>
                    <td className="reps-col table-row">{allMuscleGroups[i].secondaryMuscles}</td>
                </tr>
            );
        }
        this.setState({
            muscleGroupRows: rows
        });
    }

    render() {
        return (
            <div className="exercise-definition-table-container">
                <div className="table-card">
                    <div className="table-title">Muscle Groups</div>
                    <table className="exercise-definition-table">
                        <thead>
                            <tr>
                                <th className="id-col">Id</th>
                                <th className="exercise-col">Exercise Definition Name</th>
                                <th className="sets-col">Primary Muscles</th>
                                <th className="reps-col">Secondary Muscles</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.muscleGroupRows}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default MuscleGroups;