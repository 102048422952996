import React from 'react';
import './../css/ExerciseSummaryWidget.css';

class ExerciseSummaryWidget extends React.Component {
    constructor(props){
        super(props);
        this.getMaxWeightInstance = this.getMaxWeightInstance.bind(this);
        this.state = {
            exerciseInstances: {},
            maxWeightInstanceWeight: 0,
            maxWeightDefinitionSets: "",
            maxWeightDefinitionReps: "",
            numberOfWorkoutDefs: 0
        }
    }

    componentDidMount() {
        this.props.exerciseDefinitionService.getExerciseDefinitionsByName(this.props.exerciseDefinitionName).then(function(response){
            let definitions = response.data.Items;
            let exerciseDefinitionIds = [];
            for (let i in definitions) {
                exerciseDefinitionIds.push(definitions[i].id.S);
            }

            this.props.workoutDefinitionService.getAllWorkoutDefinitions().then(function(response){
                let allWorkoutDefs = response.data.Items;
                let workoutDefinitionIds = new Set();
                // The widget is by exercise definition name, need to get all workout defs 
                // and iterate over the exerciseDefinitionsIds object for the widget
                // If the def contains any of the exercise def ids, add the def id to the set of ids
                // Needs significant work, maybe move to a backend endpoint????
                for (let i in allWorkoutDefs){
                    let currWorkoutDef = allWorkoutDefs[i];
                    let exerciseDefIdsForWorkoutDef = currWorkoutDef.exerciseDefinitionIds.L;
                    for (let j in exerciseDefinitionIds){
                        let currExerciseDefId = exerciseDefinitionIds[j];
                        for (let k in exerciseDefIdsForWorkoutDef){
                            let workoutDefExerciseDefId = exerciseDefIdsForWorkoutDef[k].S;
                            if (currExerciseDefId === workoutDefExerciseDefId){
                                workoutDefinitionIds.add(currWorkoutDef.id.S);
                                break;
                            }
                        }
                    }
                }

                this.setState({
                    numberOfWorkoutDefs: workoutDefinitionIds.size
                });
            }.bind(this));

            this.props.exerciseInstanceService.getExerciseInstancesByDefinitonIds(exerciseDefinitionIds).then(function(response){
                let exerciseInstances = response.data.Items;

                let maxWeightInstance = this.getMaxWeightInstance(exerciseInstances);
                if (maxWeightInstance){
                    this.props.exerciseDefinitionService.getExerciseDefinitionByIds([maxWeightInstance.exerciseDefinitionId.S]).then(function(response){
                        let maxWeightDefinition = response.data.Items[0];
                        
                        let maxWeight = (maxWeightInstance && maxWeightInstance.weight.N) ? maxWeightInstance.weight.N : 0;
                        this.setState({
                            exerciseInstances: exerciseInstances,
                            maxWeightInstanceWeight: maxWeight,
                            maxWeightDefinitionReps: maxWeightDefinition.reps.N,
                            maxWeightDefinitionSets: maxWeightDefinition.sets.N,
                        });
                    }.bind(this))
                } else {
                    console.log("No max weight instance for definitionIds");
                }
            }.bind(this));
        }.bind(this));
    }

    render() {
        return (
            <div className="widget-container">
                <div className="definition-name-container">
                    <div className="definition-name">{this.props.exerciseDefinitionName}</div>
                    <div className="appears-in-container">appears in {this.state.numberOfWorkoutDefs} workout(s)</div>
                </div>
                <div className="instance-count-container">{this.state.exerciseInstances.length} instances</div>
                <div className="metric-container">
                    <div className="metric-labels-container">
                        <div className="max-weight">Max Weight</div>
                        <div className="max-weight-label">Sets: {this.state.maxWeightDefinitionSets}</div>
                        <div className="max-weight-label">Reps: {this.state.maxWeightDefinitionReps}</div>
                    </div>
                    <div className="metric-value">
                        {this.state.maxWeightInstanceWeight} lbs
                    </div>
                </div>
            </div>
        );
    }

    getMaxWeightInstance(exerciseInstances){
        let maxWeightInstance = null;
        for (let i in exerciseInstances) {
            if (!maxWeightInstance || parseInt(exerciseInstances[i].weight.N) > parseInt(maxWeightInstance.weight.N)){
                maxWeightInstance = exerciseInstances[i];
            }
        }
        return maxWeightInstance;
    }
}

export default ExerciseSummaryWidget;