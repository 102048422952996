import React from 'react';
import SummaryWidget from './SummaryWidget.js';
import './../css/Summary.css';

class Summary extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            widget1Value: 0,
            widget2Value: 0,
            widget3Value: 0,
            widget4Value: 0
        }
    }

    componentDidMount() {
        this.props.workoutDefinitionService.getAllWorkoutDefinitions().then(function(response){
            this.setState({
                widget1Value: response.data.Items.length,
            });
        }.bind(this));
        this.props.workoutInstanceService.getAllWorkoutInstances().then(function(response){
            let workoutInstances = response.data.Items;
            let totalDuration = 0;
            let countedInstances = 0;
            for (let i in workoutInstances){
                let duration = parseInt(workoutInstances[i].duration.N);
                if (duration !== -1){
                    totalDuration += duration;
                    countedInstances++;
                }
            }

            this.setState({
                widget2Value: workoutInstances.length,
                widget4Value: Math.floor(totalDuration/countedInstances).toString() + " min"
            });
        }.bind(this))
        this.props.exerciseInstanceService.getAllExerciseInstances().then(function(response){
            this.setState({
                widget3Value: response.data.Items.length,
            });
        }.bind(this));
    }

    render() {
        return (
            <div className='summary-container'>
                <SummaryWidget containerStyle="widget" title="Workouts" value={this.state.widget1Value}></SummaryWidget>
                <SummaryWidget containerStyle="widget" title="Workout Instances" value={this.state.widget2Value}></SummaryWidget>
                <SummaryWidget containerStyle="widget" title="Exercise Instances" value={this.state.widget3Value}></SummaryWidget>
                <SummaryWidget containerStyle="widget" title="Avg Workout Time" value={this.state.widget4Value}></SummaryWidget>
            </div>
        );
    }
}

export default Summary;