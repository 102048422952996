import React from 'react';
import './App.css';
import './css/Login.css';
import Toolbar from './components/Toolbar.js';
import AppRouter from './components/AppRouter.js';
import { Link } from "react-router-dom";
import ArchitecturePage from './components/ArchitecturePage.js';
import {
  Switch,
  Route
} from "react-router-dom";
import AppFooter from './components/AppFooter.js';
import { BrowserRouter as Router } from "react-router-dom";
import ExerciseInstanceService from './services/ExerciseInstanceService.js';
import ExerciseDefinitionService from './services/ExerciseDefinitionService.js';
import WorkoutDefinitionService from './services/WorkoutDefinitionService.js';
import WorkoutInstanceService from './services/WorkoutInstanceService.js';
import MuscleGroupService from './services/MuscleGroupService.js';
import AmplifyRequestService from './services/AmplifyRequestService.js';
import loginLogo from "./img/logo-snip.png";
import ErrorIcon from '@material-ui/icons/Error';
// AWS Amplify Imports
import { Auth } from 'aws-amplify';
import Amplify from "aws-amplify";
import config from "./aws-exports";
import ProductStatisticsService from './services/ProductStatisticsService';
Amplify.configure(config);

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      userStatus: "LoggedOut",
      errorText: ""
    }
    this.login = this.login.bind(this);
    this.setUpServices = this.setUpServices.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.handleUpdatePasswordKeyPress = this.handleUpdatePasswordKeyPress.bind(this);

    this.exerciseInstanceServiceApiName = "ExerciseInstanceService";
    this.workoutInstanceServiceApiName = "WorkoutInstanceService";
    this.exerciseDefinitionServiceApiName = "ExerciseDefinitionService";
    this.workoutDefinitionServiceApiName = "WorkoutDefinitionService";
    this.productStatisticsServiceApiName = "ProductStatisticsService";
    this.amplifyRequestService = new AmplifyRequestService();
    this.productStatisticsService = new ProductStatisticsService(this.amplifyRequestService, this.productStatisticsServiceApiName);
  }

  componentDidMount(){
    Auth.currentAuthenticatedUser({
      bypassCache: false
    }).then(function(user){
      if (user.signInUserSession){
        this.setUpServices(user);
        this.setState({
          userStatus: "LoggedIn"
        });
      }
    }.bind(this)).catch(error => console.log(error));
  }

  setUpServices(user){
    this.amplifyRequestService.setIdToken(user.signInUserSession.idToken)
    this.exerciseInstanceService = new ExerciseInstanceService(this.amplifyRequestService, this.exerciseInstanceServiceApiName);
    this.exerciseDefinitionService = new ExerciseDefinitionService(this.amplifyRequestService, this.exerciseDefinitionServiceApiName);
    this.workoutDefinitionService = new WorkoutDefinitionService(this.amplifyRequestService, this.workoutDefinitionServiceApiName);
    this.workoutInstanceService = new WorkoutInstanceService(this.amplifyRequestService, this.workoutInstanceServiceApiName);
    this.productStatisticsService = new ProductStatisticsService(this.amplifyRequestService, this.productStatisticsServiceApiName);
    this.muscleGroupService = new MuscleGroupService();
  }

  login(){
    let username = document.getElementById("usernameInput").value;
    let password = document.getElementById("passwordInput").value;
    Auth.signIn(username, password).then(user => {
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.user = user;
        this.setState({
          userStatus: "UpdatePassword"
        });
      } else {
        this.setUpServices(user);
        this.setState({
          userStatus: "LoggedIn"
        });
      }
    }).catch(function(event){
      let errorMessage = "Unknown Error, try again later";
      if (event.code === "UserNotFoundException"){
        errorMessage = "User not found";
      }
      if (event.code === "NotAuthorizedException"){
        errorMessage = "Invalid username or password";
      }
      this.setState({
        errorText: errorMessage
      });
    }.bind(this));
  }

  handleKeyPress(event){
    if(event.key === 'Enter'){
      this.login();
    }
  }

  handleUpdatePasswordKeyPress(event){
    if(event.key === 'Enter'){
      this.updatePassword();
    }
  }

  updatePassword(event){
    let newPassword = document.getElementById("newPasswordInput").value;
    let confirmPassword = document.getElementById("confirmPasswordInput").value;
    if (confirmPassword !== newPassword){
      this.setState({
        errorText: "Passwords don't match!"
      });
      return;
    }
    Auth.completeNewPassword(
        this.user,
        newPassword
    ).then(user => {
        this.setUpServices(user);
        this.setState({
          userStatus: "LoggedIn"
        });
    }).catch(e => {
      console.log(e);
    });
  }

  render(){
    if (this.state.userStatus === "LoggedIn"){
      return (
        <div className="App">          
          <Router>
            <Toolbar
              workoutDefinitionService={this.workoutDefinitionService}
              workoutInstanceService={this.workoutInstanceService}
              exerciseDefinitionService={this.exerciseDefinitionService}
              exerciseInstanceService={this.exerciseInstanceService}
              muscleGroupService={this.muscleGroupService}
            ></Toolbar>
            <AppRouter
              workoutDefinitionService={this.workoutDefinitionService}
              workoutInstanceService={this.workoutInstanceService}
              exerciseDefinitionService={this.exerciseDefinitionService}
              exerciseInstanceService={this.exerciseInstanceService}
              muscleGroupService={this.muscleGroupService}
              productStatisticsService={this.productStatisticsService}
            ></AppRouter>
            <AppFooter></AppFooter>
          </Router>
        </div>
      );
    } else {
      // Unauthenticated web page
      return (
        <div className="App">
          <Router>
            <Switch>
              <Route path="/architecture">
                  <Link to="/">
                      <div>Back To Login</div>
                  </Link>
                  <ArchitecturePage
                    productStatisticsService={this.productStatisticsService}
                  >
                  </ArchitecturePage>
              </Route>
              {/* Login Information, it has to be here in order to handle the authentication flow */}
              <Route path="/">
                <div className="login-window">
                  <img className="login-logo" alt="login-logo" src={loginLogo}></img>
                  <div 
                    style={(this.state.errorText.length > 0) ? {} : { display: 'none' }}
                    className="login-error-container">
                    <ErrorIcon className="login-error-icon"></ErrorIcon> {this.state.errorText}
                  </div>

                  {/* Login Fields */}

                  <div
                    style={(this.state.userStatus === "LoggedOut") ? {} : { display: 'none' }}>
                    <div className="login-input-container">
                      <div className="input-label">Email</div>
                      <input className="login-input" id="usernameInput" type="text" onKeyPress={this.handleKeyPress}></input>
                    </div>
                    <div className="login-input-container">
                      <div className="input-label">Password</div>
                      <input className="login-input" id="passwordInput" type="password" onKeyPress={this.handleKeyPress}></input>
                    </div>
                    <button className="primary-button" onClick={this.login}>Login</button>
                  </div>

                  {/* Update Password Fields */}            

                  <div
                    style={(this.state.userStatus === "UpdatePassword") ? {} : { display: 'none' }}>
                    <div className="login-input-container">
                      <div className="input-label">New Password</div>
                      <input className="login-input" id="newPasswordInput" type="password" onKeyPress={this.handleUpdatePasswordKeyPress}></input>
                    </div>
                    <div className="login-input-container">
                      <div className="input-label">Confirm Password</div>
                      <input className="login-input" id="confirmPasswordInput" type="password" onKeyPress={this.handleUpdatePasswordKeyPress}></input>
                    </div>
                    <button className="primary-button" onClick={this.updatePassword}>Update Password</button>
                  </div>
                </div>
              </Route>
            </Switch>
            <AppFooter></AppFooter>
          </Router>
        </div>
      );
    }         
  }
  
}

export default App;