module.exports = {
    getData: function() {
        return [
            {
                "id":"1",
                "exerciseDefinitionName":"Barbell Bench",
                "primaryMuscles":["triceps brachii", "pectoralis major"],
                "secondaryMuscles":["anterior deltoids", "traps", "back"],
                "sources":["https://www.bodybuilding.com/fun/schultz50.htm", "https://www.livestrong.com/article/104171-main-muscles-used-bench-press/", "http://allaboutpowerlifting.com/muscles-involved-in-the-bench/"]
            },
            {
                "id":"2",
                "exerciseDefinitionName":"Incline Barbell Bench",
                "primaryMuscles":["pectoralis major", "anterior deltoids"],
                "secondaryMuscles":["triceps brachii"],
                "sources":["http://exercise.wsu.edu/resistance/display.aspx?id=2", "https://www.skimble.com/exercises/12537-incline-bench-press-barbell-how-to-do-exercise"]
            },
            {
                "id":"3",
                "exerciseDefinitionName":"Incline Dumbbell Fly",
                "primaryMuscles":["pectoralis major"],
                "secondaryMuscles":["anterior deltoids", "triceps brachii", "posterior deltoids"],
                "sources":["https://lifestylegeeky.com/incline-dumbbell-flyes-exercise-guides/", "https://workoutlabs.com/exercise-guide/incline-dumbbell-flyes/"]
            },
            {
                "id":"4",
                "exerciseDefinitionName":"Pushups",
                "primaryMuscles":["pectoralis major", "triceps brachii", "anterior deltoids"],
                "secondaryMuscles":["biceps", "rectus abdominus", "obliques", "quadriceps", "erector spinae"],
                "sources":["https://www.livestrong.com/article/17377-muscles-used-pushup-exercises/", "https://www.womenshealthmag.com/fitness/a19973819/push-ups-muscles-worked/"]
            },
            {
                "id":"5",
                "exerciseDefinitionName":"Squat",
                "primaryMuscles":["rectus femoris", "vastus intermedius", "vastus lateralis", "vastus medialis", "gluteus maximus", "biceps femoris", "semimembranosus", "semitendinosus","rectus abdominis", "erector spinae", "obliques", "transversus abdominis"],
                "secondaryMuscles":["erectors", "soleus"],
                "sources":["https://www.livestrong.com/article/340572-which-muscles-are-used-while-squatting-with-weights/", "https://powerliftingtechnique.com/squat-muscles/"]
            },
            {
                "id":"6",
                "exerciseDefinitionName":"Kettleball Oblique",
                "primaryMuscles":["obliques"],
                "secondaryMuscles":["abdominals"],
                "sources":["https://workoutlabs.com/exercise-guide/standing-dumbbell-side-bend/"]
            },
            {
                "id":"7",
                "exerciseDefinitionName":"Plank",
                "primaryMuscles":[],
                "secondaryMuscles":[],
                "sources":[]
            },
            {
                "id":"8",
                "exerciseDefinitionName":"Dumbbell Curls",
                "primaryMuscles":[],
                "secondaryMuscles":[],
                "sources":[]
            },
            {
                "id":"9",
                "exerciseDefinitionName":"Dumbbell Rows",
                "primaryMuscles":[],
                "secondaryMuscles":[],
                "sources":[]
            },
            {
                "id":"10",
                "exerciseDefinitionName":"Hammer Curls",
                "primaryMuscles":[],
                "secondaryMuscles":[],
                "sources":[]
            },
            {
                "id":"11",
                "exerciseDefinitionName":"Barbell Row",
                "primaryMuscles":[],
                "secondaryMuscles":[],
                "sources":[]
            },
            {
                "id":"12",
                "exerciseDefinitionName":"Barbell Reverse Curl",
                "primaryMuscles":[],
                "secondaryMuscles":[],
                "sources":[]
            },
            {
                "id":"13",
                "exerciseDefinitionName":"Barbell Shrugs",
                "primaryMuscles":[],
                "secondaryMuscles":[],
                "sources":[]
            },
            {
                "id":"14",
                "exerciseDefinitionName":"Incline Dumbbell Bench",
                "primaryMuscles":[],
                "secondaryMuscles":[],
                "sources":[]
            },
            {
                "id":"15",
                "exerciseDefinitionName":"Dumbbell Bench",
                "primaryMuscles":[],
                "secondaryMuscles":[],
                "sources":[]
            },
            {
                "id":"16",
                "exerciseDefinitionName":"Dumbbell Overhead Press",
                "primaryMuscles":[],
                "secondaryMuscles":[],
                "sources":[]
            },
            {
                "id":"17",
                "exerciseDefinitionName":"Decline Pushup",
                "primaryMuscles":[],
                "secondaryMuscles":[],
                "sources":[]
            },
            {
                "id":"18",
                "exerciseDefinitionName":"Pullups",
                "primaryMuscles":[],
                "secondaryMuscles":[],
                "sources":[]
            },
            {
                "id":"19",
                "exerciseDefinitionName":"Shoulder Raises",
                "primaryMuscles":[],
                "secondaryMuscles":[],
                "sources":[]
            },
            {
                "id":"20",
                "exerciseDefinitionName":"Military Press",
                "primaryMuscles":[],
                "secondaryMuscles":[],
                "sources":[]
            },
            {
                "id":"21",
                "exerciseDefinitionName":"Dumbbell Shrugs",
                "primaryMuscles":[],
                "secondaryMuscles":[],
                "sources":[]
            },
            {
                "id":"22",
                "exerciseDefinitionName":"Skull Crushers",
                "primaryMuscles":[],
                "secondaryMuscles":[],
                "sources":[]
            },
            {
                "id":"23",
                "exerciseDefinitionName":"Farmers Stand",
                "primaryMuscles":[],
                "secondaryMuscles":[],
                "sources":[]
            }
        ];
    }
}
   