class ExerciseDefinitionService {
  constructor(amplifyRequestService, apiName) {
    this.amplifyRequestService = amplifyRequestService;
    this.apiName = apiName;
    this.exerciseDefinitionCache = {};
  }

  getAllExerciseDefinitions() {
    if (this.allDefinitionsPromise){
      return this.allDefinitionsPromise;
    }
    this.allDefinitionsPromise = this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/exercise-definitions', "GET");
    return this.allDefinitionsPromise;
  }

  getExerciseDefinitionByIds(exerciseDefinitionIds) {
    if (this.exerciseDefinitionCache[exerciseDefinitionIds.toString()]){
      return this.exerciseDefinitionCache[exerciseDefinitionIds.toString()]
    }
    let myInit = {
      queryStringParameters: {
        exerciseDefinitionIds: exerciseDefinitionIds
      }
    }
    this.exerciseDefinitionCache[exerciseDefinitionIds.toString()] = this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/exercise-definitions', "GET", myInit);
    return this.exerciseDefinitionCache[exerciseDefinitionIds.toString()]
  }

  getExerciseDefinitionsByName(definitionName) {
    if (this.exerciseDefinitionCache[definitionName]){
      return this.exerciseDefinitionCache[definitionName]
    }
    let myInit = {
      queryStringParameters: {
        exerciseDefinitionNames: [definitionName]
      }
    }
    this.exerciseDefinitionCache[definitionName] = this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/exercise-definitions', "GET", myInit);
    return this.exerciseDefinitionCache[definitionName]
  }

  addExerciseDefinition(definitionName, sets, reps){
    let exerciseDefinition = {
        body: {
            "definitionName":definitionName,
            "sets": sets,
            "reps": reps
        }
    }
    return this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/exercise-definitions', "PUT", exerciseDefinition);
  }
}

export default ExerciseDefinitionService;