import React from 'react';
import SaveIcon from '@material-ui/icons/Save';
import ErrorIcon from '@material-ui/icons/Error';
import './../css/ExerciseDefinitionBuilder.css';

class ExerciseDefinitionBuilder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasValidationError: false,
            processingText: ""
        }
        this.saveExerciseDefinition = this.saveExerciseDefinition.bind(this);
    }

    saveExerciseDefinition(){
        this.setState({
            processingText: "Validating..."
        });
        this.props.exerciseDefinitionService.getExerciseDefinitionsByName(document.getElementById("exerciseDefNameInput").value).then(function(response){
            let isValid = true;
            let defName = document.getElementById("exerciseDefNameInput").value;
            let sets = parseInt(document.getElementById("exerciseDefSetsInput").value);
            let reps = parseInt(document.getElementById("exerciseDefRepsInput").value);
            let exerciseDefinition = {
                "definitionName": defName,
                "sets": sets,
                "reps": reps
            }

            let allExerciseDefinitions = response.data.Items;

            for (let i in allExerciseDefinitions){
                let exDef = allExerciseDefinitions[i];
                if (exDef.definitionName.S === exerciseDefinition.definitionName
                    && parseInt(exDef.sets.N) === exerciseDefinition.sets
                    && parseInt(exDef.reps.N) === exerciseDefinition.reps){
                        isValid = false;
                        this.setState({
                            hasValidationError: true
                        });
                        break;
                    }
            }
    
            if (isValid){
                this.setState({
                    hasValidationError: false,
                    processingText: "Saving..."
                });
                this.props.exerciseDefinitionService.addExerciseDefinition(defName, sets, reps).then(function(response){
                    document.getElementById("exerciseDefNameInput").value = "";
                    document.getElementById("exerciseDefSetsInput").value = "";
                    document.getElementById("exerciseDefRepsInput").value = "";
                    this.setState({
                        hasValidationError: false,
                        processingText: "Saved :)"
                    });
                }.bind(this));
            }
        }.bind(this));
    }

    render() {
        return (
            <div className="add-exercise-modal-container">
                <div
                    style={this.state.hasValidationError ? {} : { display: 'none' }}
                    className="exercise-definition-validation-error-container">
                    <ErrorIcon className="error-icon"></ErrorIcon> <div className="error-text-container">Exercise definition already exists!</div>
                </div>
                <div>
                    <input className="text-input" type="text" placeholder="Definition Name" id="exerciseDefNameInput"></input>
                </div>
                <div>
                    <input className="text-input" type="text" placeholder="Sets" id="exerciseDefSetsInput"></input>
                </div>
                <div>
                    <input className="text-input" type="text" placeholder="Reps" id="exerciseDefRepsInput"></input>
                </div>
                <div className="definition-builder-footer-container">
                    <button 
                        className="secondary-button exercise-definition-save-button" 
                        onClick={this.saveExerciseDefinition}
                    >
                        <SaveIcon/> Save
                    </button>
                    <div>
                        {this.state.processingText}
                    </div>
                </div>
            </div>
        );
    }
}

export default ExerciseDefinitionBuilder;