import React from 'react';
import './../css/SummaryWidget.css';

class SummaryWidget extends React.Component {
    render() {
        return (
            <div className='summary-widget-container'>
                <div className={this.props.containerStyle}>
                    <div className="widget-title-container">
                        <div className="widget-title">{this.props.title}</div>
                    </div>
                    <div className="widget-value">{this.props.value}</div>
                </div>
            </div>
        );
    }
}

export default SummaryWidget;