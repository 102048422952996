class ExerciseInstanceService {
  constructor(amplifyRequestService, apiName) {
    this.amplifyRequestService = amplifyRequestService;
    this.apiName = apiName;
    this.exerciseInstanceCache = {};
  }

  getAllExerciseInstances() {
    if (this.allInstancesPromise){
      return this.allInstancesPromise;
    }
    this.allInstancesPromise = this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/exercise-instances', "GET");
    return this.allInstancesPromise;
  }

  getExerciseInstancesByDefinitonId(definitionId) {
    if (this.exerciseInstanceCache[definitionId]){
      return this.exerciseInstanceCache[definitionId]
    }
    let myInit = {
      queryStringParameters: {
        exerciseDefinitionIds: [definitionId]
      }
    }
    this.exerciseInstanceCache[definitionId] = this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/exercise-instances', "GET", myInit);
    return this.exerciseInstanceCache[definitionId];
  }

  getExerciseInstancesByDefinitonIds(definitionIds) {
    if (this.exerciseInstanceCache[definitionIds.toString()]){
      return this.exerciseInstanceCache[definitionIds.toString()]
    }
    let myInit = {
      queryStringParameters: {
        exerciseDefinitionIds: definitionIds
      }
    }
    this.exerciseInstanceCache[definitionIds.toString()] = this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/exercise-instances', "GET", myInit);
    return this.exerciseInstanceCache[definitionIds.toString()];
  }

  addExerciseInstance(date, exerciseDefinitionId, weight){
    let exercise = {
        body: {
            "date": date,
            "exerciseDefinitionId":exerciseDefinitionId,
            "weight": weight
        }
    }
    return this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/exercise-instances', "PUT", exercise);
  }
}

export default ExerciseInstanceService;