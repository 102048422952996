import React from 'react';
import './../css/WorkoutDefinitionBuilder.css';
import ExerciseDefinitionRowRenderer from './ExerciseDefinitionRowRenderer.js';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import ExerciseDefinitionBuilder from './ExerciseDefinitionBuilder.js';

class WorkoutDefinitionBuilder extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            "searchResultRows":[],
            "modelRows":[],
            "primaryMuscles":[],
            "secondaryMuscles":[],
            "processingText": "",
            dialogOpen: false
        }

        this.handleSearchInput = this.handleSearchInput.bind(this);
        this.addExerciseToModel = this.addExerciseToModel.bind(this);
        this.clearSearchResults = this.clearSearchResults.bind(this);
        this.calculateMuscleStuff = this.calculateMuscleStuff.bind(this);
        this.saveWorkoutDefinition = this.saveWorkoutDefinition.bind(this);
        this.toggleExerciseDialog = this.toggleExerciseDialog.bind(this);
    }

    handleSearchInput(event){
        this.props.exerciseDefinitionService.getAllExerciseDefinitions().then(function(response){
            let input = document.getElementById("exerciseSearchInput");
            // should probably cache the all items response but its actual faster than expected
            let allDefs = response.data.Items;
            let defs = [];
            let partialName = input.value;
            if (partialName && partialName.length > 0){
                defs = allDefs.filter(function(exerciseDef){
                    return exerciseDef.definitionName.S.includes(partialName);
                });
            }

            let exerciseDefRows = [];
            for (let i in defs) {
                let definition = defs[i];
                exerciseDefRows.push(
                    <div key={i} className="search-bar-row-renderer" onClick={this.addExerciseToModel.bind(this, definition)}>
                        <ExerciseDefinitionRowRenderer
                            definition={definition}
                        >
                        </ExerciseDefinitionRowRenderer>
                    </div>
                )
            }
    
            this.setState({
                "searchResultRows": exerciseDefRows
            });
        }.bind(this));
    }

    addExerciseToModel(definition, event) {
        let curr = this.state.modelRows;
        curr.push(
            // critical that key is the definition id for easy removal
            <div key={definition.id.S}>
                <div className="model-row">
                    <ExerciseDefinitionRowRenderer
                        definition={definition}
                    >
                    </ExerciseDefinitionRowRenderer> 
                </div>
                <div className="delete-icon" onClick={this.removeExerciseFromModel.bind(this, definition)}>
                    <DeleteIcon/>
                </div>        
            </div>
        );

        // Removing muscle calculation until basic functionality is sorted out
        // let muscleStuff = this.calculateMuscleStuff(curr);
        let muscleStuff = {
            "primaryMuscles":[],
            "secondaryMuscles":[]
        }

        this.setState({
            "modelRows":curr,
            "primaryMuscles":muscleStuff.primaryMuscles,
            "secondaryMuscles":muscleStuff.secondaryMuscles
        });
    }

    removeExerciseFromModel(definition, event){
        // Need to figure out how to delete items when there are duplicate exercise definitions in a workout definition
        let currRows = this.state.modelRows;
        for (let i in currRows) {
            if (currRows[i].key === definition.id.S){
                currRows.splice(i, 1);
                break;
            }
        }

        // Removing muscle calculation until basic functionality is sorted out
        // let muscleStuff = this.calculateMuscleStuff(currRows);
        let muscleStuff = {
            "primaryMuscles":[],
            "secondaryMuscles":[]
        }

        this.setState({
            "modelRows":currRows,
            "primaryMuscles":muscleStuff.primaryMuscles,
            "secondaryMuscles":muscleStuff.secondaryMuscles
        });
    }

    clearSearchResults(event){
        this.setState({
            "searchResultRows": []
        });
    }

    calculateMuscleStuff(rows){
        let primSet = new Set();
        let secSet = new Set();
        for (let i in rows) {
            let defId = rows[i].key;
            let definition = this.props.exerciseDefinitionService.getExerciseDefinitionById(defId);
            let defName = definition.definitionName;
            let muscleGroup = this.props.muscleGroupService.getMuscleGroupByExerciseDefinitionName(defName);
            if (muscleGroup){
                let prims = muscleGroup.primaryMuscles;
                for (let j in prims){
                    primSet.add(prims[j])
                }
                let seconds = muscleGroup.secondaryMuscles;
                for (let k in seconds){
                    secSet.add(seconds[k])
                }
            }
        }

        let primArray = Array.from(primSet);
        let secArray = Array.from(secSet);

        let primList = [];
        for (let l in primArray){
            primList.push(
                <li key={primArray[l]}>{primArray[l]}</li>
            )
        }

        let secList = [];
        for (let m in secArray){
            secList.push(
                <li key={secArray[m]}>{secArray[m]}</li>
            )
        }

        return {
            "primaryMuscles":primList,
            "secondaryMuscles":secList
        }
    }

    saveWorkoutDefinition() {
        let exerciseDefinitionIds = [];
        let exerciseDefinitions = [];
        let exerciseDefinitionRows = this.state.modelRows;
        for (let i in exerciseDefinitionRows){
            exerciseDefinitionIds.push(exerciseDefinitionRows[i].key);
            exerciseDefinitions.push({
                index: i,
                exerciseDefinitionId: exerciseDefinitionRows[i].key
            });
        }
        this.setState({
            processingText: "Saving..."
        });
        this.props.workoutDefinitionService.addWorkoutDefinition(document.getElementById("workoutNameInput").value, exerciseDefinitionIds, exerciseDefinitions).then(function(response){
            this.setState({
                processingText: "Saved :)"
            });
        }.bind(this));
    }

    toggleExerciseDialog(){
        this.setState({
            dialogOpen: !this.state.dialogOpen
        });
    }

    render() {
        return (
            <div className="workout-builder-container">
                <div className="workout-definition-builder-header">Create Workout</div>
                <div className="exercise-search-container">
                    <div className="exercise-definition-builder-title">
                        <span className="add-exercise-definition-icon" onClick={this.toggleExerciseDialog}><AddIcon></AddIcon></span>
                        Create Exercise Definition
                    </div>
                    <div hidden={!this.state.dialogOpen} className="exercise-definition-builder-container">
                        <ExerciseDefinitionBuilder
                            exerciseDefinitionService={this.props.exerciseDefinitionService}
                        ></ExerciseDefinitionBuilder>
                    </div>
                    <div className="search-input-container">
                        <input 
                            id="exerciseSearchInput" 
                            type="text" 
                            name="search" 
                            placeholder="Search" 
                            className="search-input" 
                            onInput={this.handleSearchInput}>
                        </input>
                    </div>
                    <div className="search-results-container">
                        {this.state.searchResultRows}
                    </div>
                </div>
                <div className="workout-draft-container">
                    <input 
                        id="workoutNameInput" 
                        className="workout-name-input" 
                        type="text" 
                        name="workoutName" 
                        placeholder="Workout Name"
                    >
                    </input>
                    <button 
                        hidden={!this.state.modelRows.length > 0} 
                        className="secondary-button" 
                        onClick={this.saveWorkoutDefinition}
                    >
                        <SaveIcon/> Save
                    </button>
                    <div className="workout-definition-builder-processing-text-container">{this.state.processingText}</div>
                    <div className="no-workouts-selected-shadow" hidden={this.state.modelRows.length > 0}>
                        <div className="no-workouts-text">Select exercises to see more information</div>
                    </div>
                    <div className="model-definition-container" hidden={!this.state.modelRows.length > 0}>
                        <div className="model-rows-container">
                            {this.state.modelRows}
                        </div>
                        <div className="muscle-overview-container">
                            <div className="muscle-overview-title">Muscle Overview</div>
                            <div>
                                <div>Primary</div>
                                <ul>
                                    {this.state.primaryMuscles}
                                </ul>
                            </div>
                            <div>
                                <div>Secondary</div>
                                <ul>
                                    {this.state.secondaryMuscles}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WorkoutDefinitionBuilder;