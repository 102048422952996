class ProductStatisticsService {
    constructor(amplifyRequestService, apiName) {
      this.amplifyRequestService = amplifyRequestService;
      this.apiName = apiName;
    }
  
    getProductStatistics() {
      if (this.productStatistics){
        return this.productStatistics;
      }
      this.productStatistics = this.amplifyRequestService.makeRequest(this.apiName, '/product-statistics', "GET");
      return this.productStatistics;
    }
  }
  
export default ProductStatisticsService;